// This composable is used to set up instances of Donut Selectors, be they static/dynamic or single/multi.
// The logic herein is in two main parts
// 1) Menu Positioning:
// // Locating position and size of the menu when it opens based on the button, which including generating
// // a random ID for the component to prevent multiple instances being open at once.
// 2) Selection Propagation and Rendering
// // We provide a keyMap for options that don't subscribe to standard 'value', 'label', 'description' nomenclature
// // and add a watcher to detect when each component's `selection` value has changed, updating it in the parent
import { useStore } from 'vuex';
import { ref, computed, watch, onUnmounted } from 'vue';
import Random from 'lib/random';

const useBaseSelector = ({ props, emit, selectorEl, selection }) => {
  const { state, commit, dispatch } = useStore();

  // Selector Menu Opening
  const selectorId = ref(Random.uuid());
  const selectorMenuIsOpen = computed(() => state.selector.openSelectorId === selectorId.value);
  const onSelectorButtonClick = () => { commit('update', { module: 'selector', key: 'openSelectorId', value: selectorId.value }); };

  // Menu Resizing and Positioning
  const selectorMenuWidth = ref(0);
  const selectorMenuPosition = ref({ top: 0, left: 0 });
  const selectorMenuStyle = computed(() => ({ ...selectorMenuPosition.value, width: `${selectorMenuWidth.value}px` }));
  const calculateSelectorMenuWidth = () => {
    const rect = selectorEl.value.getBoundingClientRect();
    selectorMenuWidth.value = rect.right - rect.left;
  };
  const calculateSelectorMenuPosition = () => {
    const rect = selectorEl.value.getBoundingClientRect();
    selectorMenuPosition.value = { top: rect.bottom + 0, left: rect.left + 0 };
  };
  const closeSelectorMenu = () => {
    commit('update', { module: 'selector', key: 'openSelectorId', value: null });
  };
  watch(selectorMenuIsOpen, (newValue) => {
    if (newValue) {
      // selectorMenuIsOpen is true, we've just opened the menu
      calculateSelectorMenuWidth();
      calculateSelectorMenuPosition();
      window.addEventListener('click', closeSelectorMenu);
      window.addEventListener('scroll', calculateSelectorMenuPosition);
    } else {
      // selectorMenuIsOpen is false, we've just closed the menu
      window.removeEventListener('click', closeSelectorMenu);
      window.removeEventListener('scroll', calculateSelectorMenuPosition);
    }
  });

  // Selection Monitoring
  watch(() => props.modelValue, (newValue) => {
      // There may be locations where the v-model of a selector is changed by a different
      // UI element elsewhere on the page. Watch for this and update accordingly.
      selection.value = newValue;
    });

  // Selection Rendering
  const keyMap = computed(() => {
    // keyMap is for formatting options to render correct in the menu and selection fields
    // For instance, if a User object was coming through and we wanted to pass its ID to a
    // controller, render its name in the selector component, and show its email as an added description,
    // the keyMap object might look like { value: 'id', label: 'name', description: 'email' }
    const defaults = { value: 'value', label: 'label', description: 'description' };
    return { ...defaults, ...props.keyMap };
  });
  const propagateNewSelection = (newSelection) => {
    // The default here is to just emit update:modelValue, but in some instances (for instance, if the value
    // is in VueX state) we do not want to just update the value as it is provided. We may want to call a function
    // or dispatch an action with the new values. In this case, we would not be emitting and directly updating a new value
    // on the parent component, but it would be updated via the alternate functionality.
    if (props.updateFunction) {
      props.updateFunction(newSelection);
    } else if (props.updateAction) {
      dispatch(props.updateAction, newSelection);
    } else {
      emit('update:modelValue', newSelection);
    }
  };
  watch(selection, (newValue) => {
    propagateNewSelection(newValue);
  });

  // Lifecycle Hooks
  onUnmounted(() => {
    window.removeEventListener('click', closeSelectorMenu);
    window.removeEventListener('onScroll', calculateSelectorMenuPosition);
  });

  return {
    onSelectorButtonClick,
    selectorMenuIsOpen,
    selectorMenuStyle,
    calculateSelectorMenuPosition,
    keyMap,
  };
};

export default useBaseSelector;
