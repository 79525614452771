// This composable is used to facilitate searching in a static selector
import { computed } from 'vue';

const useStaticSelector = ({ options, keyMap, searchQuery }) => {
  // Text Search
  // eslint-disable-next-line arrow-body-style
  const filteredOptions = computed(() => {
    // Only pass options the labels and/or optional descriptions of which contain the searchQuery as a substring
    const searchRegex = new RegExp(searchQuery.value, 'i');
    return options.filter((option) => {
      if (searchRegex.test(option[keyMap.value.label])) return true;
      if (searchRegex.test(option[keyMap.value.description])) return true;
      return false;
    });
  });

  return { filteredOptions };
};

export default useStaticSelector;
