import { mapState, mapGetters } from 'vuex';
import Minutes from 'lib/minutes';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    eventLink() {
      let link = `/${this.event.route_flow_type}`;
      if (this.tag) {
        link += `/tags/${this.tag.id}`;
      }
      if (this.isForSession) {
        return `${link}/session_events/${this.event.id}`;
      }
      return `${link}/events/${this.event.id}/edit`;
    },

    target() {
      if (this.isEmail && this.event.email.to_text) {
        return this.event.email.to_text;
      } if (this.event.event_type === 'batch_channel_creating') {
        return this.event.channel_configuration.name;
      } if (this.event.channel_send) {
        if (this.event.channel != null) {
          return this.event.channel.name;
        }
        return '{#channel}';
      } if (this.event.recipient_channel_configuration) {
        return this.event.recipient_channel_configuration.name;
      } if (this.event.role) {
        return this.event.role.name;
      } if (this.event.user) {
        return this.event.user.name;
      } if (this.event.channel) {
        return this.event.channel.name;
      } if (this.event.event_type === 'batch_cal_meeting') {
        return 'Cohort event';
      }
      return 'Someone';
    },

    secondTarget() {
      if (this.event.intro) {
        if (this.event.intro.groupSize) {
          if (parseInt(this.event.intro.groupSize, 10) === 2) {
            return 'Someone else';
          }
          return `${this.event.intro.groupSize - 1} others`;
        }
        return this.event.intro.to.name || 'Someone else';
      }
    },

    targets() {
      if (this.event.event_type === 'batch_channel_creating') {
        const numInvitees = this.event.channel_configuration.num_invitees;
        return `${numInvitees} invitee${numInvitees === 1 ? '' : 's'}`;
      }
      const targets = [this.target];
      if (this.secondTarget) {
        targets.push(this.secondTarget);
      }
      return targets.join(', ');
    },

    formattedTime() {
      if (this.isForSession) {
        return this.event.occurs_at;
      }
      if (this.event.send_time != null) return this.event.send_time;
      return Minutes.formatMinutes(this.event.minute_in_day);
    },

    formattedMessage() {
      if (this.event.message) {
        return Donut.Formatter.formatTextForSlack(this.event.message, { forEmail: this.isEmail });
      }
    },

    isEmail() {
      return this.event.email != null;
    },

    ...mapState([
      'tag',
    ]),
    ...mapGetters('onboardingEventsCalendar', ['isForSession']),
  },
};
