// This composable is to be used on components that need to determine whether to make themselves visible.
// Typically this will be because they calculate something in their scripts that determines whether
// to display the templates. Components using this composable should pass in...
// // 1. A computed prop or ref called shouldDisplay that is the determining factor of displaying.
// //    NOTE: Use shouldDisplay at the top level of the component's template in a v-if.
// // 2. An optional emit with `updateShouldDisplay` defined. This will inform the component's parent.
// // 3. An optional method called onPseudoMounted. Any functionality that you'd normally use in
// //    onMounted that you only want to call if shouldDisplay proves to be true.
import { ref, watch } from 'vue';

const useSelfDetermination = ({ shouldDisplay, emit, onPseudoMounted }) => {
  const isUnmounted = ref(true);

  watch(shouldDisplay, (newValue) => {
    if (emit) emit('updateShouldDisplay', newValue);
    if (newValue && isUnmounted.value) {
      isUnmounted.value = false;
      if (onPseudoMounted) onPseudoMounted();
    }
  }, { immediate: true });
};

export default useSelfDetermination;
